<template>
  <page-layout>
    <template #header>
      <div class="mb-8">
        <k-title>{{ $t('catalog.pageTitle') }}</k-title>
      </div>
    </template>

    <template>
      <div v-if="categories.length === 0">
        {{ $t('catalog.category.noCatalog') }}
      </div>
      <v-container v-else class="px-0 mt-12 pt-10">
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3" 
                 v-for="({id,image,name}) in categories" 
                 v-bind:key="id">
            <v-card flat tile class="fill-height d-flex flex-column">
              <div height="100px">
                <VImg :src="`${image}&type=thumbnail`"
                      :lazy-src="`${image}&type=small`"
                      height="100px"
                      max-width="100%"
                      class="pa-1"
                      contain/>
              </div>
              <v-card-title class="text-break">{{ name }}</v-card-title>
              <v-card-actions class="mt-auto">
                <VSpacer/>
                <v-btn :disabled="isGoingToNavigate !== false"
                       color="primary"
                       @click="navigateToCategory(id)"
                       depressed
                       tile>
                  {{ $t('catalog.category.actions.show') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </page-layout>
</template>

<script>
import { EventBus } from '@/application/EventBus.js';
import KTitle from '@/components/layout/KTitle.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import { categoryList } from '@/modules/catalog/api';
import i18n from '@/plugins/i18n.js';

export default {
  name: 'CategoryOverview',
  components: {
    KTitle,
    PageLayout,
  },
  data() {
    return {
      categories: [],
      isGoingToNavigate: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { data: { data } } = await categoryList();
      next(vm => vm.setCategoryList(data));
    } catch (e) {
      EventBus.$emit('snackbar', {
        color: 'error',
        text: i18n.t('errors.catalogList'),
      });
      next(e);
    }
  },
  methods: {
    async navigateToCategory(categoryId) {
      this.isGoingToNavigate = categoryId;
      await this.$router.push({
        name: 'catalog.product',
        params: { categoryId },
      });
    },
    setCategoryList(data) {
      this.categories = data;
    },
  },
};
</script>

<style scoped>
.v-card__title {
  word-break: break-word;
}
</style>

